import { PaginationSchema } from '../common';
import { analyticsContactSchema, IDSchema, orderDirTypes, trafficSourceTypeSchema } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/employees
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
});

const inputQueryParamsSchema = z.object({
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z
    .enum(['name', 'email', 'phoneNumber', 'department', 'notification', 'firstOpenDate', 'trafficSource'])
    .default('name'),
  ...PaginationSchema.shape,
});

const responseSchema = z.object({
  rows: z.array(
    analyticsContactSchema.and(
      z.object({
        notification: z.enum(['bounce', 'sent', 'none']),
        firstOpenDate: z.date(),
        trafficSource: trafficSourceTypeSchema,
      }),
    ),
  ),
  count: z.number(),
});

const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getEmployeesAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetEmployeesAnalytics = {
  Response: z.output<typeof getEmployeesAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getEmployeesAnalyticsSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getEmployeesAnalyticsSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getEmployeesAnalyticsSchemas.inputSchema>;
};
