import { IDSchema } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/modules
 */
const actionCountsSchema = z.record(z.number());
const submoduleAnalyticsSchema = z.record(actionCountsSchema);
const moduleAnalyticsSchema = z.record(z.union([submoduleAnalyticsSchema, actionCountsSchema]));

const inputPathParamsSchema = z.object({
  messageId: IDSchema,
});
const responseSchema = z.record(moduleAnalyticsSchema);
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
});

export const getModuleAnalyticsSchemas = {
  inputPathParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetModuleAnalytics = {
  Response: z.output<typeof getModuleAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getModuleAnalyticsSchemas.inputPathParamsSchema>;
  InputParsed: z.output<typeof getModuleAnalyticsSchemas.inputSchema>;
};
