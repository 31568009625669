<article
  *ngIf="item"
  class="ao-messagelist-item"
  [ngClass]="{
    'ao-messagelist-item--unread': item.unread,
    'ao-messagelist-item--card': cardMode,
    'ao-messagelist-item--kahoot-challenge': kahootMode,
    'ao-messagelist-item--preview-mode': previewMode,
    'ao-messagelist-item--not-clickable': !clickable,
    'ao-messagelist-item--has-box-shadow': hasBoxShadow
  }"
  (click)="clicked.emit({ source: 'message' })"
>
  <!-- main content -->
  <span
    class="o-media o-media--reverse ao-messagelist-item__link"
    [ngClass]="{
      'ao-messagelist-item__link--no-footer': !hasFooter
    }"
  >
    <!-- right side (image) -->
    <div *ngIf="media" class="o-media__figure">
      <div
        class="ao-messagelist-item__media"
        [ngClass]="{
          'ao-messagelist-item__media--video': ['video', 'youtube'].includes(media.thumbnailSource),
          'ao-messagelist-item__media--squared': squaredThumbnails
        }"
      >
        <!-- image -->
        <ng-container *ngIf="hasImage; else thumbnailIcon">
          <div
            *ngIf="squaredThumbnails"
            class="ao-messagelist-item__thumbnail-canvas"
            [style.backgroundImage]="'url(' + imageThumbnailUrl + ')'"
            [aoUnsplashTrackViews]="media.unsplashImageId"
          ></div>
          <img *ngIf="!squaredThumbnails" [aoSrc]="imageThumbnailUrl" [aoUnsplashTrackViews]="media.unsplashImageId" />
        </ng-container>
        <ng-template #thumbnailIcon>
          <ao-icon-avatar
            *ngIf="showThumbnailPlaceholder"
            size="full"
            [iconName]="thumbnailPlaceholderIcon"
            [iconSize]="32"
            [avatarColor]="thumbnailPlaceholderColor"
            borderRadius="4px"
          ></ao-icon-avatar>
        </ng-template>
        <!-- duration (if the image is a video thumbnail) -->
        <div class="ao-messagelist-item__video-indicator" *ngIf="['video', 'youtube'].includes(media.thumbnailSource)">
          <ao-icon name="play_circle_filled" [size]="16"></ao-icon>
          <span class="ao-messagelist-item__duration">{{ duration }}</span>
        </div>
      </div>
    </div>

    <!-- left side (text content) -->
    <div class="o-media__body">
      <!-- categories-->
      <div class="ao-messagelist-item__tags">
        <ao-category-tags
          class="ao-messagelist-item__categories"
          *ngIf="showCategories && item.categories; let categories"
          [categories]="categories"
          [useTheme]="useTheme"
          [iconBackgroundColor]="iconBackgroundColor"
          [limit]="categoryLimit"
        ></ao-category-tags>
      </div>

      <!-- title and description -->
      <h3
        class="ao-font-body2-bold ao-messagelist-item__headline"
        [ngClass]="{ 'ao-messagelist-item__headline--kahoot-title': kahootMode }"
        [innerHTML]="title"
      ></h3>
      <div
        *ngIf="description"
        class="ao-font-small ao-messagelist-item__copy-preview"
        [ngClass]="{ 'ao-messagelist-item__copy-preview--kahoot-description': kahootMode }"
        [innerHTML]="description"
      ></div>
    </div>
  </span>

  <!-- Footer / interaction area -->
  <footer>
    <!-- extra info -->
    <div class="ao-messagelist-item__meta" *ngIf="showMetaData">
      <!-- publish date -->
      <time
        class="ao-messagelist-item__date"
        *ngIf="!!item.displayDate"
        [aoTime]="item.displayDate"
        [format]="'messageStats'"
      ></time>

      <!-- social (interaction)stats -->
      <div class="ao-messagelist-item__stats" *ngIf="item.socialStats; let stats">
        <!-- reactions -->
        <div class="ao-messagelist-item__stat ao-messagelist-item__stat--reactions" *ngIf="stats.reactions">
          <ao-icon [size]="20" name="mood"></ao-icon>
          <span>{{ stats.reactions | friendlyNumber }}</span>
        </div>
        <!-- comments -->
        <div class="ao-messagelist-item__stat" *ngIf="stats.comments">
          <ao-icon [size]="20" name="chat_bubble_outline"></ao-icon>
          <span>{{ stats.comments | friendlyNumber }}</span>
        </div>
        <!-- views -->
        <div class="ao-messagelist-item__stat" *ngIf="stats.views">
          <ao-icon [size]="20" name="remove_red_eye"></ao-icon>
          <span>{{ stats.views | friendlyNumber }}</span>
        </div>
      </div>
    </div>

    <!-- Quiz info  -->
    <div class="ao-messagelist-item__callout" *ngIf="showCallout">
      <!-- results -->
      <div class="ao-messagelist-item__callout-content" *ngIf="item.quiz; let quiz">
        <!-- title -->
        <span class="ao-messagelist-item__callout-title">{{ 'Status' | translate }}</span>

        <!-- completed stats -->
        <span *ngIf="showCompletedCalloutMessage" class="ao-messagelist-item__callout-content">
          {{ 'You have completed all actions' | translate }}
        </span>

        <!-- incomplete stats You have completed all actions-->
        <span *ngIf="['incomplete'].includes(quiz.status)" class="ao-messagelist-item__callout-content">
          {{
            'You have completed #numAnswered# of #numQuestions# actions'
              | translate: { numAnswered: quiz.numberOfQuestionsAnswered, numQuestions: quiz.numberOfQuestions }
          }}
        </span>
      </div>

      <!-- failed quiz (Retake) -->
      <a
        *ngIf="showRetakeQuizLink"
        class="ao-messagelist-item__callout-action"
        (click)="$event.stopPropagation(); clicked.emit({ source: 'retake' })"
      >
        <span>{{ 'Retake' | translate }}</span>
        <ao-icon [size]="12" name="arrow_forward"></ao-icon>
      </a>

      <!-- Unfinished quiz (continue) -->
      <a
        *ngIf="['incomplete'].includes(item.quiz?.status)"
        class="ao-messagelist-item__callout-action"
        (click)="$event.stopPropagation(); clicked.emit({ source: '' })"
        href="javascript:;"
        translate
      >
        <span translate>Continue</span>
        <ao-icon [size]="12" name="arrow_forward"></ao-icon>
      </a>
    </div>

    <!-- Academy info   -->
    <div class="ao-messagelist-item__academy" *ngIf="item.type === 'academy' && item.academyData; let data">
      <!-- expiration date nudge -->
      <div
        class="ao-messagelist-item__academy-warning"
        *ngIf="cardMode && data.expirationDate && _math.abs(academyExpirationDays) < 31 && data.progress !== 1"
        [ngClass]="{ 'ao-messagelist-item__academy-warning--expired': academyExpirationDays < 0 }"
      >
        <ao-icon name="schedule" [size]="20"></ao-icon>
        <span
          *ngIf="academyExpirationDays > 0; else deadlineElapsed"
          class="ao-messagelist-item__academy-warning__text"
        >
          {{ 'Hurry up! This program is due in #days# days' | translate: { days: academyExpirationDays } }}
        </span>
        <ng-template #deadlineElapsed>
          <span class="ao-messagelist-item__academy-warning__text">
            {{ 'This program was due #days# days ago' | translate: { days: _math.abs(academyExpirationDays) } }}
          </span>
        </ng-template>
      </div>

      <!-- academy info progress -->
      <ao-academy-inline-progress
        *ngIf="data.progress >= 0"
        [academyData]="data"
        [themedColor]="themedColor"
        [showPercentage]="false"
        [themedContent]="themedContent"
        [showCompletionState]="data.progress === 1"
      ></ao-academy-inline-progress>
    </div>
  </footer>
</article>
