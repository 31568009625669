import { PaginationSchema } from '../common';
import { analyticsContactListSchema, getModuleEventAnalyticsOrderByTypes, IDSchema, orderDirTypes } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:id/analytics/trafficSource/:source
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
  trafficSource: z.enum(['email', 'push', 'sms', 'app']),
});
const inputQueryParamsSchema = z.object({
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z.enum(getModuleEventAnalyticsOrderByTypes).default('name'),
  ...PaginationSchema.shape,
});
const responseSchema = analyticsContactListSchema;
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getEmployeesListTrafficSourceSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetEmployeesListTrafficSource = {
  Response: z.output<typeof getEmployeesListTrafficSourceSchemas.responseSchema>;
  InputPathParams: z.input<typeof getEmployeesListTrafficSourceSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getEmployeesListTrafficSourceSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getEmployeesListTrafficSourceSchemas.inputSchema>;
};
