// Adapted from https://github.com/wollio/angular2_photoswipe

import {
  AfterContentInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ZoomableImage } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { WINDOW } from '@ao/utilities';
import { UnsplashTrackingService } from '../../services/unsplash-tracking.service';
import { ZoomableImageService } from '../../services/zoomable-image.service';

interface PhotoswipeImage {
  unsplash_image_id?: string;
  media_file_id?: number;
  src: string;
  w: number;
  h: number;
  title?: string;
}

interface Dimension {
  width: number;
  height: number;
}

@Component({
  selector: 'ao-zoomable-image',
  templateUrl: './zoomable-image.component.html',
  styleUrls: ['./zoomable-image.component.scss'],
  standalone: false,
})
export class ZoomableImageComponent implements AfterContentInit {
  @HostBinding('class.ao-zoomable-image') className = true;

  @Input() coverImage = false;
  @Input() autoFill = false;
  @Input() unsplashImageId?: string;
  @Input() imageByUrl?: string;

  // by setting this to false, we can delay tracking until the imag is rendered in the view (fx in image gallery)
  @Input() trackUnsplash = true;
  @Input() imgSizes? = '(max-width: 600px) 100vw, 600px';

  @Input() zoomableImage?: ZoomableImage; // single image (deprecate after we switch to media
  @Input() set images(value: MediaItem[]) {
    // refactor image structure to Photoswipe friendly format
    this.zoomableImages = value?.length
      ? value
          .filter((item) => !item.type || ['image', 'video'].includes(this.type(item.type)))
          .map((item) => {
            const originalImage = item.images?.find((image) => image.size?.original);
            const srcset = item.images
              ? item.images
                  .filter((image) => !!image.size?.width)
                  .map((image) => `${image.url} ${image.size.width}w`)
                  .join(',')
              : `${item.url} ${item.width}w`;

            return {
              type: item.type,
              src: item.url,
              srcset: srcset,
              width: originalImage?.size?.width || item.width,
              height: originalImage?.size?.height || item.height,
              originalUrl: originalImage?.url || item.url,
              caption: item.caption,
              unsplash_image_id: item.unsplash_image_id,
              media_file_id: item.media_file_id,
            };
          })
      : [];
  }

  @Input() currentImage? = 0;
  @Input() disableLightbox = false;
  @HostBinding('class.ao-zoomable-image--no-lightbox') noClickClass() {
    return this.disableLightbox;
  }

  @Output() imageLoad: EventEmitter<void> = new EventEmitter();
  @Output() imageError: EventEmitter<void> = new EventEmitter();
  @Output() shareLinkClick: EventEmitter<{ e: Event; target: HTMLElement }> = new EventEmitter();
  @Output() fullScreenImageSwipe: EventEmitter<any> = new EventEmitter();

  private innerWidth: number;
  private innerHeight: number;

  zoomableImages: ZoomableImage[];
  pswp: any;

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _zoomableImageService: ZoomableImageService,
    private unsplashTrackingService: UnsplashTrackingService,
  ) {}

  ngAfterContentInit() {
    this.innerWidth = this._window.innerWidth;
    this.innerHeight = this._window.innerHeight;
  }

  private async openPhotoSwipe() {
    const PhotoSwipe = await import('photoswipe');
    const PhotoSwipeUI_Default = await import('photoswipe/dist/photoswipe-ui-default');
    const PSWP: HTMLElement = <HTMLElement>document.querySelectorAll('.pswp')[0];
    const options = {
      index: this.currentImage,
      allowPanToNext: true,
      spacing: 0.12,
      bgOpacity: 1.0,
      mouseUsed: false,
      loop: true,
      pinchToClose: true,
      closeOnScroll: true,
      closeOnVerticalDrag: true,
      hideAnimationDuration: 333,
      showAnimationDuration: 333,
      showHideOpacity: false,
      escKey: true,
      arrowKeys: true,
      shareEl: false,
      galleryUID: 'zoom',
    };

    this.pswp = new PhotoSwipe.default(PSWP, PhotoSwipeUI_Default.default, await this.getImagesAsPhotoswipe(), options);

    this.pswp.listen('shareLinkClick', (e, target) => {
      this.shareLinkClick.emit({ e, target });
    });
    this.pswp.init();
    this.pswp.listen('initialZoomInEnd', () => {
      this._zoomableImageService.openLightBox();
    });
    this.pswp.listen('destroy', () => {
      this._zoomableImageService.closeLightBox();
    });

    this.pswp.listen('afterChange', () => {
      this.fullScreenImageSwipe.emit(this.pswp.currItem.media_file_id);
      if (this.pswp.currItem.unsplash_image_id) {
        this.unsplashTrackingService.registerView(this.pswp.currItem.unsplash_image_id);
      }
    });
  }

  // If image is landscape or square, set width to window width and height to width * ratio
  // If image is portrait, set height to window height and width to height / ratio
  private getImageDimensions(w: number, h: number): Dimension {
    const ratio = h / w;
    if (w >= h) {
      return {
        width: this.innerWidth,
        height: this.innerWidth * ratio,
      };
    } else {
      return {
        width: this.innerHeight / ratio,
        height: this.innerHeight,
      };
    }
  }

  private async getImagesAsPhotoswipe(): Promise<PhotoswipeImage[]> {
    // todo: how do we make this dynamic?
    // todo: this is broken but need to switch branches

    if (this.zoomableImage) {
      const { width, height, originalUrl, media_file_id } = this.zoomableImage;
      const imgDimensions = this.autoFill ? this.getImageDimensions(width, height) : { width, height };
      return [
        {
          src: originalUrl,
          media_file_id,
          w: imgDimensions.width,
          h: imgDimensions.height,
        },
      ];
    }

    if (this.imageByUrl) {
      const { naturalWidth, naturalHeight } = await this.getImageMeta(this.imageByUrl);

      return [{ src: this.imageByUrl, w: naturalWidth, h: naturalHeight }];
    }

    return this.zoomableImages
      .filter((item) => !item.type || this.type(item.type) === 'image')
      .map((item) => {
        const { width, height, originalUrl, caption, unsplash_image_id, media_file_id } = item;
        const imgDimensions = this.autoFill ? this.getImageDimensions(width, height) : { width, height };
        return {
          unsplash_image_id,
          media_file_id,
          src: originalUrl,
          w: imgDimensions.width,
          h: imgDimensions.height,
          title: caption,
        };
      });
  }

  onImageLoaded(mediaFileId) {
    this.imageLoad.emit(mediaFileId);
  }

  onImageError() {
    this.imageError.emit();
  }

  onClick() {
    if (!this.disableLightbox) {
      this.openPhotoSwipe();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = this._window.innerWidth;
    this.innerHeight = this._window.innerHeight;
  }

  type(type: string) {
    switch (type.split('/')[0]) {
      case 'image':
        return 'image';
      case 'video':
        return 'video';
      case 'audio':
        return 'audio';
      default: {
        return '';
      }
    }
  }

  private getImageMeta(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }
}
