import { PaginationSchema } from '../common';
import { IDSchema } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/modules/upload/:moduleId
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
  moduleId: IDSchema,
});
const inputQueryParamsSchema = z.object({
  ...PaginationSchema.shape,
});
const responseSchema = z.object({
  rows: z.array(
    z.object({
      contactId: z.number(),
      anonymous: z.boolean(),
      firstName: z.string(),
      lastName: z.string(),
      fileType: z.string(),
      fileUrl: z.string(),
      createdAt: z.date(),
    }),
  ),
  count: z.number(),
});
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getUploadModuleAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetUploadModuleAnalytics = {
  Response: z.output<typeof getUploadModuleAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getUploadModuleAnalyticsSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getUploadModuleAnalyticsSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getUploadModuleAnalyticsSchemas.inputSchema>;
};
