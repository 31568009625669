import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { AdminSupportSettings, TERMS_OF_USE_CONSTANT } from '@ao/data-models';
import { EMAIL_REGEX } from '@ao/shared-constants';
import { ImageSource } from '@ao/shared-data-models';
import { atLeastOneValidator } from '@ao/utilities';

export interface UpdateContactConfirmationObject {
  msisdn: string | null;
  email: string | null;
  allowContactInfo: boolean;
  sendHomePage?: boolean;
  formIsDirty: boolean;
}

@Component({
  selector: 'ao-update-contact-info',
  templateUrl: './update-contact-info.component.html',
  styleUrls: ['./update-contact-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UpdateContactInfoComponent {
  @HostBinding('class.ao-update-contact-info') className = true;

  @Input() support: AdminSupportSettings;
  @Input() firstTimeFlow = false;
  @Input() isManagerView = false;
  @Input() emailIntegration = false;
  @Input() phoneIntegration = false;
  @Input() initials: string;
  @Input() avatarUrl: string | SafeUrl;
  @Input() avatarImages: ImageSource[];
  @Input() contactName: string;
  @Input() contactFirstName: string;
  @Input() contactInfoValidationDisabled: boolean;
  @Input() set termsOfUseAccepted(value: boolean) {
    if (value) {
      this.acceptTermsOfUse();
    }
  }

  @Input() set email(value: string) {
    // disable / endable integration fields
    if (this.emailIntegration) {
      this._form.controls['email'].disable();
    } else {
      this._form.controls['email'].enable();
    }

    if (value) {
      this._form.controls.email.setValue(value);
    }
  }

  @Input() set msisdn(value: string) {
    // disable / endable integration fields
    if (this.phoneIntegration) {
      this._form.controls['msisdn'].disable();
    } else {
      this._form.controls['msisdn'].enable();
    }

    if (value) {
      this._form.controls.msisdn.setValue(value);
    }
  }

  @Input() set allowContactInfo(value: boolean) {
    this._form.controls.hideContactInfo.setValue(!value);
  }

  @Input() set keyPolicy(value: string[]) {
    this._emailOnlyKeyPolicy = false;
    this._msisdnOnlyKeyPolicy = false;
    // single value key policy using email or phone - need to make sure they fill it in
    if (value && value.length === 1) {
      if (value[0] === 'email') {
        this._emailOnlyKeyPolicy = true;
        this._form.controls['email'].setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_REGEX),
        ]);
      } else if (value[0] === 'msisdn') {
        this._msisdnOnlyKeyPolicy = true;
        this._form.controls['msisdn'].setValidators([Validators.required]);
      }
    }
  }
  @Input() set termsOfUseEnabled(value: boolean) {
    if (value) {
      this._form.controls['termsOfUse'].enable();
    } else {
      this._form.controls['termsOfUse'].disable();
    }
  }
  @Input() set termsOfUseText(value: string) {
    if (value) {
      this._TermsOfUseText = value;
    }
  }

  @Output() confirm = new EventEmitter<UpdateContactConfirmationObject>();
  @Output() toggleSupportModal = new EventEmitter<void>();

  _displayErrors = false;
  defaultCountryCode: '+45';
  _TermsOfUseText: string = TERMS_OF_USE_CONSTANT.DEFAULT_TERMS_OF_USE;
  _termsOfUseModalVisible = false;
  _submitDebounce = false;
  _emailOnlyKeyPolicy = false;
  _msisdnOnlyKeyPolicy = false;

  private fb = inject(FormBuilder);
  private cdr = inject(ChangeDetectorRef);

  _form = this.fb.group(
    {
      msisdn: this.fb.control(''),
      email: this.fb.control('', {
        validators: [Validators.email, Validators.pattern(EMAIL_REGEX)],
      }),
      termsOfUse: this.fb.control({ value: false, disabled: true }, [Validators.requiredTrue]),
      hideContactInfo: this.fb.control(false),
      sendHomePage: this.fb.control(false),
    },
    { validator: atLeastOneValidator(Validators.required, ['email', 'msisdn']) },
  );

  continue(event) {
    // Check for feature flag
    if (this.contactInfoValidationDisabled) {
      this._form.setValidators(null);
      this._form.updateValueAndValidity();
    }

    event.preventDefault();

    // prevent re-submit while animating out of view
    this._submitDebounce = true;
    setTimeout(() => {
      this._submitDebounce = false;
    }, 1000);

    if (!this._form.valid) {
      // display all error texts & set highlights to full red
      this._displayErrors = true;
      // poke angular to render changes
      this._form.markAsDirty();
      this._form.updateValueAndValidity();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
      return;
    }

    setTimeout(() => {
      this.confirm.emit({
        msisdn: this._form.controls.msisdn.value,
        email: this._form.controls.email.value,
        allowContactInfo: !this._form.controls.hideContactInfo.value,
        ...(this.isManagerView ? { sendHomePage: this._form.controls.sendHomePage.value } : {}),
        formIsDirty: this._form.dirty,
      });
    }, 0);
  }

  toggleTermsOfUse(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this._termsOfUseModalVisible = !this._termsOfUseModalVisible;
  }

  acceptTermsOfUse() {
    this._termsOfUseModalVisible = false;
    this._form['controls'].termsOfUse.setValue(true);
  }
}
