import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { simpleDebounce } from '@ao/utilities';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabComponent } from './tab.component';

@Component({
  selector: 'ao-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TabsComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @HostBinding('class.ao-tabs--sticky')
  @Input()
  stickyTabs = false;
  @HostBinding('class.ao-tabs') className = true;
  @HostBinding('class.ao-tabs--allow-pagination')
  @Input()
  allowPagination: boolean;

  @HostBinding('class.ao-tabs--admin-ui') get adminBoldStyle() {
    return this.allowPagination && this.adminUi;
  }
  @Input() adminUi: boolean;

  @HostBinding('class.ao-tabs--bottom-border')
  @Input()
  bottomBorder?: boolean;

  @Input() scrollableTabs = false;

  @HostBinding('class.ao-tabs--extra-height')
  @Input()
  extraHeight?: boolean = false;

  @ViewChild('viewport', { static: true }) viewport: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  dimensions: { left: number; width: number }[];
  contentWidth: number;

  _paginated = false;
  offsetLeft = 0;
  navigating = false;

  get showNavigation() {
    return this.allowPagination && this._paginated;
  }

  @HostListener('window:resize', ['$event'])
  checkForPagination = simpleDebounce(() => {
    if (!this.allowPagination) return;
    const viewportWidth = (<HTMLElement>this.viewport.nativeElement).offsetWidth;
    const changed = viewportWidth < this.contentWidth && !this._paginated;
    this._paginated = viewportWidth < this.contentWidth;
    if (this.offsetLeft) {
      this._paginated = true;
    }
    if (changed && !this.navigating) {
      // give it some time to add the nav buttons
      setTimeout(() => {
        this.adjustOffset();
        // Was getting a console error while navigating away from App login tab "Attempt to use a destroyed view: detectChanges"
        if (!this.cdRef['destroyed']) {
          this.cdRef.detectChanges();
        }
      }, 1000);
    } else {
      this.adjustOffset();
      // Was getting a console error while navigating away from App login tab "Attempt to use a destroyed view: detectChanges"
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }, 100);

  private destroy$ = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.allowPagination) {
      this.computeTabDimensions();
      this.tabs.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.computeTabDimensions());
    }
  }

  ngAfterViewChecked() {
    if (this.allowPagination) {
      this.checkForPagination();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  adjustOffset() {
    const tabs = this.tabs.toArray();
    const index = tabs.findIndex((t) => t.active);
    if (index === -1) {
      return;
    }
    const left = this.dimensions[index].left;
    const right = left + this.dimensions[index].width;
    const viewportWidth = this.viewport.nativeElement.offsetWidth;
    if (viewportWidth > this.contentWidth) {
      this.navigating = false;
      this.offsetLeft = 0;
      this._paginated = false;
    } else if (!this.navigating) {
      let adjOffsetLeft = this.offsetLeft;

      adjOffsetLeft = Math.max(adjOffsetLeft, this.fixOffset(right - viewportWidth));
      adjOffsetLeft = Math.min(adjOffsetLeft, this.fixOffset(left));

      this.offsetLeft = adjOffsetLeft;
    }
  }

  fixOffset(val) {
    const viewportWidth = (<HTMLElement>this.viewport.nativeElement).offsetWidth;
    return Math.min(this.contentWidth - viewportWidth, Math.max(0, val));
  }

  canPageForward() {
    if (!this.tabs.length) {
      return false;
    }
    const lastTab = this.dimensions[this.dimensions.length - 1];
    return lastTab && lastTab.left + lastTab.width > this.viewport.nativeElement.clientWidth + this.offsetLeft;
  }

  nextPage() {
    if (!this.canPageForward()) return;
    this.navigating = true;

    const totalWidth = this.viewport.nativeElement.clientWidth + this.offsetLeft;
    const index = this.dimensions.findIndex((t) => t.left + t.width > totalWidth);
    const tab = index === -1 ? this.tabs.first : this.tabs.find((_, i) => i === index);

    let offset = 0;
    if (this.viewport.nativeElement.clientWidth > this.dimensions[index].width) {
      // viewport width *greater* than tab width: usual positioning
      offset = tab.nativeElement.offsetLeft;
    } else {
      // viewport width *smaller* than tab width: positioning at the *end* of current tab to let
      // pagination "for loop" to proceed correctly on next tab when nextPage() is called again
      offset =
        tab.nativeElement.offsetLeft + (this.dimensions[index].width - this.viewport.nativeElement.clientWidth + 1);
    }
    this.offsetLeft = this.fixOffset(offset);
  }

  /**
   * Slides the tabs over approximately one page backward.
   */
  previousPage() {
    if (this.offsetLeft === 0) return;
    this.navigating = true;
    const index = this.dimensions.findIndex((t) => t.left + t.width > this.offsetLeft);
    const tab = index === -1 ? this.tabs.first : this.tabs.find((_, i) => i === index);
    let offset = 0;
    if (this.viewport.nativeElement.clientWidth > this.dimensions[index].width) {
      // viewport width *greater* than tab width: usual positioning
      offset = tab.nativeElement.offsetLeft + this.dimensions[index].width - this.viewport.nativeElement.clientWidth;
    } else {
      // viewport width *smaller* than tab width: positioning at the *beginning* of current tab to let
      // pagination "for loop" to break correctly on previous tab when previousPage() is called again
      offset = tab.nativeElement.offsetLeft;
    }
    this.offsetLeft = this.fixOffset(offset);
  }

  onContentClick() {
    this.navigating = false;
    this.checkForPagination();
  }

  private computeTabDimensions() {
    this.contentWidth = (<HTMLElement>this.wrapper.nativeElement).offsetWidth;
    this.dimensions = this.tabs.map((t) => ({ left: t.nativeElement.offsetLeft, width: t.nativeElement.offsetWidth }));
  }
}
