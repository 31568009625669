import { IDSchema, trafficSourceTypeSchema } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/basics
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
});
const inputQueryParamsSchema = null;
const inputBodyParamsSchema = null;
const responseSchema = z.object({
  publishedTo: z.number(),
  openedBy: z.number(),
  notifiedTo: z.number(),
  trafficSource: z.record(trafficSourceTypeSchema, z.number()),
  uniquePublicWithinClientOpenCount: z.number(),
  nonuniqueOpenCount: z.number(),
});

const inputParsed = z.object({
  ...inputPathParamsSchema.shape,
});

export const getBasicAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  inputBodyParamsSchema,
  responseSchema,
  inputParsed,
};

export type GetBasicAnalytics = {
  Response: z.output<typeof getBasicAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getBasicAnalyticsSchemas.inputPathParamsSchema>;
  InputParsed: z.output<typeof getBasicAnalyticsSchemas.inputParsed>;
};
