import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { BrandingColor, IconSize } from '@ao/data-models';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
  selector: 'ao-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TooltipIconComponent {
  @Input() narrow = false;
  @Input() iconSize?: IconSize = 14;
  @Input() icon = 'info';
  @Input() iconColor: BrandingColor | 'inkLighter' | 'currentColor' = 'inkLighter';
  @Input() tooltipText: string;
  @Input() tooltipPosition: 'start' | 'center' | 'end' = 'center';

  @ViewChild('popover', { static: true }) popoverElemRef?: SatPopover;
}
