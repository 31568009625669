import { z } from 'zod';

export const IDSchema = z.string().pipe(z.coerce.number().min(1)); // redefined from @ao-utils/zodValidate, but importing it causes circular dependency :/
export const IDSchemaNumber = z.number().int();

const moduleTypes = [
  'video',
  'text',
  'buttongroup',
  'feedback',
  'image',
  'like',
  'audio',
  'slide', // "presentation"
  'slider',
  'youtube',
  'datapicker',
  'upload',
  'chart',
  'messagelist',
  'socialFeed',
  'embed',
  'grid',
  'confirm',
  'kahootchallenge',
  'media',
  // used?
  'contactinfo',
  'pagebreak',
  'unknown',
  'channelPreferenc',
  'optInOut',
  'auth',
  'smsverify',
  'group',
  'post',
  'homepage',
] as const;

export const ModuleTypeSchema = z.enum(moduleTypes);
export type ModuleType = z.output<typeof ModuleTypeSchema>;

const messageAnalyticsEventTypes = [
  'view',
  'click',
  'upload',
  'confirmed',
  'play0',
  'play10',
  'play20',
  'play30',
  'play40',
  'play50',
  'play60',
  'play70',
  'play80',
  'play90',
  'play100',
  'create',
  'send',
  'update',
  'submit',
] as const;

const SliderEventsSchema = z.string().startsWith('slider_');
export const EventTypeSchema = z.enum(messageAnalyticsEventTypes).or(SliderEventsSchema);
export type EventType = z.output<typeof EventTypeSchema>;

export const orderDirTypes = ['asc', 'desc'] as const;

const OrderDirTypeSchema = z.enum(orderDirTypes);
export type OrderDirType = z.output<typeof OrderDirTypeSchema>;

export const trafficSourceTypeSchema = z.enum(['email', 'sms', 'push', 'app']);

export const analyticsContactSchema = z.object({
  contactId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  countryCode: z.string(),
  phoneNumber: z.string(),
  department: z.string(),
});

export const analyticsContactListSchema = z.object({
  rows: z.array(analyticsContactSchema),
  count: z.number(),
});

export const getModuleEventAnalyticsOrderByTypes = ['name', 'email', 'phoneNumber', 'department'] as const;
