import { PaginationSchema } from '../common';
import {
  analyticsContactSchema,
  EventTypeSchema,
  IDSchema,
  IDSchemaNumber,
  ModuleTypeSchema,
  orderDirTypes,
} from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/modules/:moduleType/:moduleId/:submoduleId/:event
 */
const getModuleEventAnalyticsOrderByTypes = ['name', 'email', 'phoneNumber', 'department'] as const;

const inputPathParamsSchema = z.object({
  messageId: IDSchema,
  moduleType: ModuleTypeSchema,
  moduleId: IDSchema,
  submoduleId: z.preprocess((value) => {
    if (value === 'null') {
      value = 0;
    }
    return Number(value);
  }, IDSchemaNumber),
  event: EventTypeSchema,
});
const inputQueryParamsSchema = z.object({
  inverse: z.enum(['true', 'false']).transform((value) => value === 'true'),
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z.enum(getModuleEventAnalyticsOrderByTypes).default('name'),
  ...PaginationSchema.shape,
});
const responseSchema = z.object({
  rows: z.array(analyticsContactSchema),
  count: z.number(),
});
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getModuleEventAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetModuleEventAnalytics = {
  Response: z.output<typeof getModuleEventAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getModuleEventAnalyticsSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getModuleEventAnalyticsSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getModuleEventAnalyticsSchemas.inputSchema>;
};
