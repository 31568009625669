<span
  ><ao-icon
    [name]="icon"
    [size]="iconSize"
    [color]="iconColor"
    [aoTooltip]="tRef"
    [tooltipAlign]="tooltipPosition"
  ></ao-icon>
  <ao-tooltip #tRef>
    {{ tooltipText }}
  </ao-tooltip>
</span>
