import { PaginationSchema } from '../common';
import { IDSchema, orderDirTypes } from './schemas';
import { z } from 'zod';

/**
 * Schema of GET /messages/:messageId/analytics/departments
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
});
const inputQueryParamsSchema = z.object({
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z.enum(['name', 'employees', 'openRate']).default('name'),
  ...PaginationSchema.shape,
});
const responseSchema = z.object({
  rows: z.array(
    z.object({
      name: z.string(),
      employees: z.number(),
      openRate: z.number(),
    }),
  ),
  count: z.number(),
});

const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getDepartmentsAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetDepartmentsAnalytics = {
  Response: z.output<typeof getDepartmentsAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getDepartmentsAnalyticsSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getDepartmentsAnalyticsSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getDepartmentsAnalyticsSchemas.inputSchema>;
};
