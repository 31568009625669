import { inject, Inject, Injectable } from '@angular/core';

import * as Bowser from 'bowser';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, map, merge, Observable } from 'rxjs';

import { BrowserName, BrowserOSName, BrowserPlatformType } from './browser.model';
import { NativeBridgeService } from './native-bridge.service';
import { WINDOW } from './tokens';

@Injectable()
export class BrowserService {
  private nativeBridge = inject(NativeBridgeService);
  private _cachedScrollbarWidth = undefined;

  // Expose bowser for direct usage
  public bowser: Bowser.Parser.Parser;

  connectionStatus$: Observable<'online' | 'offline'> = merge(
    fromEvent(this.window, 'online'),
    fromEvent(this.window, 'offline'),
  ).pipe(map((event) => event.type as 'online' | 'offline'));

  constructor(
    @Inject(WINDOW) private window: Window,
    private cookieService: CookieService,
  ) {
    this.initialiseBowser();
    this.setupCssHelperClasses();
  }

  // expose for testing
  initialiseBowser(ua?: string) {
    this.bowser = Bowser.getParser(ua || this.window.navigator.userAgent);
  }

  cookiesEnabled() {
    try {
      // Create cookie
      document.cookie = 'cookietest=1; SameSite=None; Secure';
      const ret = document.cookie.includes('cookietest=');
      // Delete cookie
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=None; Secure';
      return ret;
    } catch (e) {
      return false;
    }
  }

  setupCssHelperClasses() {
    const html = this.window.document.documentElement;

    const browser = this.sanitizeClassName(this.browserName()?.toLowerCase());
    const os = this.sanitizeClassName(this.browserOS()?.toLowerCase());
    const platform = this.sanitizeClassName(this.platformType()?.toLowerCase());

    if (browser) html.classList.add(browser);
    if (os) html.classList.add(os);
    if (platform) html.classList.add(platform);
  }

  sanitizeClassName(name?: string): string | undefined {
    return name?.trim() ? name.replace(/\s+/g, '-') : undefined;
  }

  browserName(): BrowserName {
    return <BrowserName>this.bowser.getBrowserName();
  }

  browserOS(): BrowserOSName {
    return <BrowserOSName>this.bowser.getOSName();
  }

  platformType(): BrowserPlatformType {
    return <BrowserPlatformType>this.bowser.getPlatformType();
  }

  isMobileOS(): boolean {
    return this.platformType() === BrowserPlatformType.mobile;
  }

  isMobileOrTabletOS(): boolean {
    return this.platformType() === BrowserPlatformType.mobile || this.platformType() === BrowserPlatformType.tablet;
  }

  // Detect if the browser is running in Private Browsing mode
  isPrivateMode() {
    return new Promise((resolve) => {
      const on = () => resolve(true); // is in private mode
      const off = () => resolve(false); // not private mode
      const testLocalStorage = () => {
        try {
          if (localStorage.length) off();
          else {
            localStorage.x = 1;
            localStorage.removeItem('x');
            off();
          }
        } catch (e) {
          // Safari only enables cookie in private mode
          // if cookie is disabled then all client side storage is disabled
          // if all client side storage is disabled, then there is no point
          // in using private mode
          this.window.navigator.cookieEnabled ? on() : off();
        }
      };
      // Chrome & Opera
      if ((<any>this.window).webkitRequestFileSystem) {
        return void (<any>this.window).webkitRequestFileSystem(0, 0, off, on);
      }
      // Firefox
      if ('MozAppearance' in document.documentElement.style) {
        const db = indexedDB.open('test');
        db.onerror = on;
        db.onsuccess = off;
        return void 0;
      }
      // Safari
      // eslint-disable-next-line no-useless-escape
      const isSafari = this.window.navigator.userAgent.match(/(Version|CriOS)\/([0-9\._]+).*Safari/);
      if (isSafari) {
        const version = parseInt(isSafari[2], 10);
        if (version >= 11) {
          try {
            (<any>this.window).openDatabase(null, null, null, null);
            return off();
          } catch (_) {
            return on();
          }
        } else if (version < 11) {
          return testLocalStorage();
        }
      }

      return off();
    });
  }

  getScrollbarWidth() {
    if (typeof this._cachedScrollbarWidth === 'undefined') {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      document.body.appendChild(scrollDiv);
      this._cachedScrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }
    return this._cachedScrollbarWidth;
  }

  isNativeAppCookieSet() {
    return this.cookieService.check('isNativeApp');
  }

  get isAndroid() {
    return this.browserOS() === BrowserOSName.Android;
  }

  get isIOS(): boolean {
    return this.browserOS() === BrowserOSName.iOS;
  }

  get isNative(): boolean {
    return this.isAndroid || this.isIOS;
  }

  get isStandaloneIOS() {
    return (this.window.navigator as any).standalone === true;
  }

  get isStandaloneOther() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  isPWA() {
    return this.isStandaloneIOS || this.isStandaloneOther;
  }

  supportsAddToHomescreen() {
    const browserName = this.browserName();
    const androidSupported = [BrowserName.Chrome, BrowserName.SamsungInternet];

    return this.isIOS || (this.isAndroid && androidSupported.indexOf(browserName) > -1);
  }

  isMobileSafari() {
    const isMobileSafari = this.isIOS && this.browserName() === BrowserName.Safari;
    return isMobileSafari;
  }
}
