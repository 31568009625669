import { MessageListMessage, SearchResultItem } from '@ao/data-models';

export function mapSearchResultItemToMessageListMessage(
  itemValue: SearchResultItem,
  query: string,
): MessageListMessage {
  const mappedMessageListMessage = {
    id: itemValue.id,
    subject: `${itemValue.id}: ${itemValue.title}`,
    created_at: itemValue.accessCreatedAt,
    displayDate: new Date(itemValue.accessCreatedAt).getTime() ?? 0,
    last_sent_date: itemValue.accessCreatedAt,
    unread: false,
    title: addEmphasisToSearchResultItemText(itemValue.title, query) ?? '',
    text: addEmphasisToSearchResultItemText(itemValue.text, query) ?? '',
    oneLiner: addEmphasisToSearchResultItemText(itemValue.text, query) ?? '',
    type: itemValue.type,
    media: itemValue.media,
    mediaPreview: itemValue.mediaPreview ? itemValue.mediaPreview : itemValue.media ? itemValue.media : undefined,
    categories: itemValue.categories.map((category) => ({ name: addEmphasisToSearchResultItemText(category, query) })),
    socialStats: itemValue.socialStats,
    likes_enabled: itemValue.likesEnabled,
    academyData: itemValue.academyData ?? undefined,
  };

  return mappedMessageListMessage;
}

/**
 * Highlights query words or phrases within a given text by wrapping them in a `<span>` tag with a specified class.
 * It combines adjacent query matches into a single highlight and handles partial word matches at the start of words.
 * The function addresses various potential issues related to Unicode, security, and performance.
 *
 * @param {string} item - The text in which to highlight query words or phrases.
 * @param {string} query - The search query containing words or phrases to highlight within the item text.
 * @param {number} [maxTotalPhrases=50] - The maximum number of phrases to generate from the query.
 * @returns {string} - The item text with matching query words or phrases wrapped in `<span class="search-highlight">`.
 */
function addEmphasisToSearchResultItemText(item: string, query: string, maxTotalPhrases = 50): string {
  if (!item || !query) {
    return item;
  }

  // Escape HTML entities in item text to prevent XSS vulnerabilities
  const escapeHtml = (text: string) =>
    text.replace(/[&<>"'/]/g, (s) => {
      const entityMap: { [key: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
      };
      return entityMap[s];
    });
  const escapedItem = escapeHtml(item);

  // Normalize item and query strings using locale-aware methods and Unicode normalization
  const normalizedItem = escapedItem.normalize('NFC');
  const normalizedQuery = query.normalize('NFC').toLocaleLowerCase();

  // Split the query into words (including words shorter than min length)
  const allQueryWords = normalizedQuery
    .replace(/[^\p{L}\p{N}\s'-]+/gu, '')
    .split(/\s+/u)
    .filter(Boolean);

  if (allQueryWords.length === 0) {
    return escapedItem;
  }

  // Generate phrases from query words with a maximum length of 3 words
  const phrases = new Set<string>();
  const minPhraseLength = 3; // Minimum total length of phrase (excluding spaces)
  const maxPhraseWords = 3; // Maximum number of words in a phrase
  let phraseCount = 0; // Counter for total phrases generated

  outerLoop: for (let i = 0; i < allQueryWords.length; i++) {
    const phraseWords = [];
    for (let j = i; j < Math.min(i + maxPhraseWords, allQueryWords.length); j++) {
      phraseWords.push(allQueryWords[j]);
      const phrase = phraseWords.join(' ');
      // Remove spaces to calculate actual character length
      const phraseLength = phrase.replace(/\s+/g, '').length;
      if (phraseLength >= minPhraseLength) {
        phrases.add(phrase);
        phraseCount++;
        if (phraseCount >= maxTotalPhrases) {
          break outerLoop; // Exit both loops if the limit is reached
        }
      }
    }
  }

  // Extract individual query words of minimum length
  const queryWords = allQueryWords.filter((word) => word.length >= 3);

  // Combine phrases and query words into patterns
  const patterns = Array.from(phrases)
    .concat(queryWords)
    .map((phrase) => phrase.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&'))
    .sort((a, b) => b.length - a.length); // Prioritize longer patterns

  if (patterns.length === 0) {
    return escapedItem;
  }

  // Combine patterns into one regex pattern
  const combinedPattern = patterns.join('|');

  // Create regex to find matches, capturing only the matching part
  const regex = new RegExp(`(?<![\\p{L}\\p{N}'-])(${combinedPattern})([\\p{L}\\p{N}'-]*)`, 'giu');

  // Collect matches
  const matches: { start: number; end: number }[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(normalizedItem)) !== null) {
    const matchStart = match.index;
    const matchEnd = matchStart + match[1].length; // Only the length of the matched pattern
    matches.push({ start: matchStart, end: matchEnd });
  }

  // Combine adjacent matches
  const combinedMatches = [];
  if (matches.length > 0) {
    let currentMatch = { ...matches[0] };
    for (let i = 1; i < matches.length; i++) {
      const nextMatch = matches[i];
      const textBetween = normalizedItem.slice(currentMatch.end, nextMatch.start);
      if (/^\s*$/u.test(textBetween)) {
        // If only whitespace between matches, extend current match
        currentMatch.end = nextMatch.end;
      } else {
        // Otherwise, push current match and start a new one
        combinedMatches.push(currentMatch);
        currentMatch = { ...nextMatch };
      }
    }
    combinedMatches.push(currentMatch);
  }

  // Build the result string
  let result = '';
  let lastIndex = 0;

  for (const match of combinedMatches) {
    // Append text before the match
    result += normalizedItem.slice(lastIndex, match.start);
    // Append highlighted match
    result += `<span class="search-highlight">${normalizedItem.slice(match.start, match.end)}</span>`;
    lastIndex = match.end;
  }
  // Append any remaining text
  result += normalizedItem.slice(lastIndex);

  return result;
}
