<!-- media (images/videos) gallery -->
<div
  class="ao-media-embed__wrapper"
  #wrapper
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouch($event)"
  (touchend)="onTouchEnd()"
>
  <ul
    class="ao-media-embed__list"
    [ngClass]="{ 'ao-media-embed__list--panning': panning }"
    [style.width.%]="100 * _media.length"
    [ngStyle]="style"
  >
    <!-- individual images -->
    <li class="ao-media-embed__media" *ngFor="let item of _media; let i = index; trackBy: trackByFn">
      <!-- black background -->
      <div class="ao-media-embed__media-inner" [style.padding-top.%]="item.zoomableImage?.mediaHeight">
        <div class="ao-media-embed__media-container" [ngSwitch]="type(item)" *ngIf="loaded[i]">
          <!-- Image -->
          <ng-container *ngSwitchCase="'image'">
            <ao-zoomable-image
              class="ao-media-embed__media-image"
              [images]="_media"
              [currentImage]="i"
              [disableLightbox]="disableLightbox"
              [trackUnsplash]="selectedItem === i"
              (fullScreenImageSwipe)="mediaSwipe.emit($event)"
              (imageLoad)="firstImageLoaded.emit($event)"
              (imageError)="firstImageError.emit()"
            ></ao-zoomable-image>
          </ng-container>

          <!-- next / previous arrows -->
          <ng-container *ngSwitchCase="'video'">
            <div *ngIf="status(item) === 'processing'" class="ao-font-tiny ao-media-embed__processing">
              <div class="ao-media-embed__progress">{{ item.progress }}%</div>
              <div class="ao-media-embed__info ao-font-small">{{ item.name }}</div>
              <div class="ao-media-embed__info space" translate>Video will show when ready</div>
            </div>
            <div *ngIf="status(item) === 'error'" class="ao-font-tiny ao-media-embed__processing">
              <ao-icon [size]="48" class="ao-media-embed__video-icon" name="clear"></ao-icon>
              <div class="ao-media-embed__progress">{{ item.name }}</div>
              <div class="ao-media-embed__info" translate>There was an error processing this video file</div>
            </div>

            <ao-media
              class="ao-media-embed__media-video"
              *ngIf="status(item) === 'ready'"
              #video
              [sources]="sources[item.id]"
              [fluid]="allowFluid && !isPortrait(item)"
              [parentHeight]="true"
              [poster]="item.thumbnailUrl | fixProtocol"
            ></ao-media>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>

  <!-- next / previous arrows -->
  <button class="ao-media-embed__button ao-media-embed__button--prev" *ngIf="hasPrev" (click)="showPrev()">
    <ao-icon name="arrow_back_ios"></ao-icon>
  </button>
  <button class="ao-media-embed__button ao-media-embed__button--next" *ngIf="hasNext" (click)="showNext()">
    <ao-icon name="arrow_forward_ios"></ao-icon>
  </button>

  <!-- Caption -->
  <div class="ao-media-embed__media-caption" *ngIf="_media?.[selectedItem]?.caption">
    {{ _media[selectedItem].caption }}
  </div>
</div>

<!-- media pagination -->
<div class="ao-media-embed__pagination-helper" *ngIf="_media?.length > 1">
  <div *ngFor="let item of pagination" class="ao-media-embed__pagination-circle-wrapper">
    <div
      class="ao-media-embed__pagination-circle"
      [ngClass]="{
        'ao-media-embed__pagination-circle--active': item === selectedPagination,
        'ao-media-embed__pagination-circle--big': item <= selectedPagination + 1 && item >= selectedPagination - 1
      }"
    ></div>
  </div>
</div>

<!-- Audio Clips -->
<ul class="ao-media-embed__audio-attachments" *ngIf="audio?.length">
  <li *ngFor="let aud of audio; trackBy: trackByFn" class="ao-media-embed__audio-item">
    <ao-uploaded-file
      *ngIf="status(aud) !== 'ready'"
      [name]="aud.name"
      [type]="aud.type"
      [processing]="true"
      [inComment]="inComment"
      [progress]="aud.progress"
      [audioUrl]="aud?.sources?.length ? aud.sources[0].url : aud?.url"
      [ngClass]="{ 'ao-media-embed__file--in-comment': inComment }"
    ></ao-uploaded-file>
    <ao-audio-waveform-player
      *ngIf="status(aud) === 'ready'"
      [sources]="sources[aud.id]"
      [hasBackground]="true"
      [showBorder]="inComment"
    ></ao-audio-waveform-player>
  </li>
</ul>

<!-- Downloadable files -->
<ul class="ao-media-embed__file-attachments" *ngIf="files?.length">
  <li *ngFor="let file of files; trackBy: trackByFn" class="ao-media-embed__file-item">
    <span
      class="ao-media-embed__file-item-link"
      *ngIf="isNativeApp; else downloadLink"
      (click)="nativeAppDownloadFile(file.url)"
    >
      <ao-uploaded-file
        [name]="file.name"
        [type]="file.type"
        class="ao-media-embed__file"
        [ngClass]="{ 'ao-media-embed__file--in-comment': inComment }"
      ></ao-uploaded-file>
    </span>
    <ng-template #downloadLink>
      <a class="ao-media-embed__file-item-link" [href]="file.url" [attr.title]="file.name" [attr.target]="'_blank'">
        <ao-uploaded-file
          [name]="file.name"
          [type]="file.type"
          class="ao-media-embed__file"
          [ngClass]="{ 'ao-media-embed__file--in-comment': inComment }"
        ></ao-uploaded-file>
      </a>
    </ng-template>
  </li>
</ul>
