import { PaginationSchema } from '../common';
import { analyticsContactListSchema, getModuleEventAnalyticsOrderByTypes, IDSchema, orderDirTypes } from './schemas';
import { z } from 'zod';

/**
 * many endpoints use the same schema
 * /messages/:messageId/analytics/employees/publishedTo
 * /messages/:messageId/analytics/employees/notifiedTo
 * /messages/:messageId/analytics/employees/opened
 */
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
});
const inputQueryParamsSchema = z.object({
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z.enum(getModuleEventAnalyticsOrderByTypes).default('name'),
  ...PaginationSchema.shape,
});
const responseSchema = analyticsContactListSchema;
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getEmployeesListSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetEmployeesList = {
  Response: z.output<typeof getEmployeesListSchemas.responseSchema>;
  InputPathParams: z.input<typeof getEmployeesListSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getEmployeesListSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getEmployeesListSchemas.inputSchema>;
};
