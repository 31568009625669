import { PaginationSchema } from '../common';
import { IDSchema, orderDirTypes } from './schemas';
import { z } from 'zod';

/*
 * Schema of GET /messages/:messageId/analytics/modules/feedback/:moduleId
 */
const getFeedbackModuleAnalyticsOrderByTypes = ['name', 'feedback', 'createdAt'] as const;
const inputPathParamsSchema = z.object({
  messageId: IDSchema,
  moduleId: IDSchema,
});
const inputQueryParamsSchema = z.object({
  search: z.string().optional(),
  orderDir: z.enum(orderDirTypes).default('asc'),
  orderBy: z.enum(getFeedbackModuleAnalyticsOrderByTypes).default('name'),
  ...PaginationSchema.shape,
});
const responseSchema = z.object({
  rows: z.array(
    z.object({
      contactId: z.number(),
      anonymous: z.boolean(),
      firstName: z.string(),
      lastName: z.string(),
      feedback: z.string(),
      createdAt: z.date(),
    }),
  ),
  count: z.number(),
});
const inputSchema = z.object({
  ...inputPathParamsSchema.shape,
  ...inputQueryParamsSchema.shape,
});

export const getFeedbackModuleAnalyticsSchemas = {
  inputPathParamsSchema,
  inputQueryParamsSchema,
  responseSchema,
  inputSchema,
};

export type GetFeedbackModuleAnalytics = {
  Response: z.output<typeof getFeedbackModuleAnalyticsSchemas.responseSchema>;
  InputPathParams: z.input<typeof getFeedbackModuleAnalyticsSchemas.inputPathParamsSchema>;
  InputQueryParams: z.input<typeof getFeedbackModuleAnalyticsSchemas.inputQueryParamsSchema>;
  InputParsed: z.output<typeof getFeedbackModuleAnalyticsSchemas.inputSchema>;
};
